import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store' 
import Empty from '../layout/EmptyPage.vue'



const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }else{
    next('/Home')
  }
  
}
const ifOnlyAuth = (to, from, next) => {
  if(store.getters.isAuthenticated){
      next()
      return;
  }else{
    next('/access')
  }
   
}
const ifAuthenticated = (to, from, next) => {

    if(store.getters.isAuthorized(to)){
        store.dispatch("currentMenu", to.name);
        next()
        return;
    }else{
      next('/access')
    }
     
}

const routes = [
  {
      path: '/Home',
      name: 'Home',
      component: Empty,
      beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
              next()
              return
          }
          next('/Login')
      },
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../components/Pages/Login.vue'),
    beforeEnter: ifNotAuthenticated ,
    meta: {
      Layout : "plainLayout",
    },
  },
  {

    path: '/redirectTo',
    name: 'RedirectTo',
    component: () => import('../components/Pages/RedirectTo.vue'),
    beforeEnter: ifNotAuthenticated ,
    meta: {
      Layout : "plainLayout",
    },
  },
 
  {
    path: '/empty',
    name: 'Empty',
    component: Empty,
    beforeEnter: ifAuthenticated,
  },

  /*PLANEACION */
  {
    path: '/Plan/',
    name: 'PlaneacionIndex',
    component: () => import('../views/Planeacion/Index.vue'),
    beforeEnter: ifAuthenticated ,
  },
  {
    path: '/Plan/Detalle/:id?',
    name: 'PlaneacionDetalle',
    component: () => import('../views/Planeacion/Details.vue'),
    beforeEnter: ifAuthenticated ,
  },

  {
    path: '/Plan/Utilidad/',
    name: 'PlanDidac_Fac',
    component: () => import('../views/Planeacion/Utilities.vue'),
    beforeEnter: ifAuthenticated ,
  },
  {
    path: '/perfil',
    name: 'profile',
    component: () => import('../views/Generales/PanelEmpleado.vue'),
    beforeEnter: ifOnlyAuth ,
  },
  //Revision
  {
    path: '/Revision/Esquema/',
    name: 'Rev_Esquema',
    component: () => import('../views/Planeacion/ReviewSchema.vue'),
    beforeEnter: ifAuthenticated ,
  },

  {
    path: '/Revision/Docente/',
    name: 'Rev_Docente',
    component: () => import('../views/Planeacion/Review.vue'),
    beforeEnter: ifAuthenticated ,
  },

  {
    path: '/Revision/DesempenoDocente/:id',
    name: 'Rev_DesempenoDoc_Layout',
    component: () => import('../views/Planeacion/ReviewAppDoc.vue'),
    beforeEnter: (to, from, next)=>{
      if(from.name == "Rev_Docente"){
        next();
        return;
      }      
      else
        next('/Home')
    },
    meta: {
      Layout : "customLayout",
    },
  },
 
  {
    path: '/Evaluacion',
    name: 'Rev_DesempenoEst_Layout',
    component: () => import('../views/Planeacion/ReviewAppEst.vue'),
    // beforeEnter: (to, from, next)=>{
    //   if(from.name == "Rev_Docente"){
    //     next();
    //     return;
    //   }      
    //   else
    //     next('/Home')
    // },
    meta: {
      Layout : "customLayout",
    },
  },

  /*GENERALES*/
  {
    path: "/General/Facultad",
    name: 'Facultad',
    component: () => import('../views/Generales/Facultades.vue'),
    beforeEnter: ifAuthenticated ,
  },

  {
    path: '/:pathMatch(.*)*',
    name: "notfound",
    component: () => import('../components/Pages/NotFound.vue'),
    meta: {
      Layout : "plainLayout",
    },
  },
  {
    path: '/error',
    name: "error",
    component: () => import('../components/Pages/Error.vue'),
    meta: {
      Layout :"plainLayout",
    },
  },
  {
    path: '/access',
    name: "access",
    component: () => import('../components/Pages/Access.vue'),
    meta: {
      Layout : "plainLayout",
    },
  },


  /*ADMIN */
  {
    path: "/Seguridad",
    name: 'Seguridad',
    component: () => import('../views/Admin/Seguridad.vue'),
    beforeEnter: ifAuthenticated ,
  },
  {
    path: "/Modulo",
    name: 'Modulo',
    component: () => import('../views/Admin/Modulos.vue'),
    beforeEnter: ifAuthenticated ,
  },

  {
    path: "/Integracion/Empleado",
    name: 'Empleado',
    component: () => import('../views/Admin/Empleados.vue'),
    beforeEnter: ifAuthenticated ,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
