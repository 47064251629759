<template>

<!--Excited Request -->
 <span class="sat_icon">
    <svg version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
	viewBox="0 0 56.7 56.7" style="enable-background:new 0 0 56.7 56.7;" xml:space="preserve">
        <g>
            <path class="st0" d="M707.4,263.9l6.7,13.5l14.9,2.2l-10.8,10.5l2.5,14.8l-13.3-7l-13.3,7l2.5-14.8l-10.8-10.5l14.9-2.2
                L707.4,263.9z M707.4,263.9"/>
            <path class="st1" d="M721.2,305.6l-13.8-7.2l-13.8,7.2l2.6-15.4l-11.2-10.9l15.4-2.2l6.9-14l6.9,14l15.4,2.2l-11.2,10.9
                L721.2,305.6z M686.6,279.8L697,290l-2.5,14.3l12.8-6.8l12.8,6.8l-2.5-14.3l10.4-10.1l-14.4-2.1l-6.4-13l-6.4,13L686.6,279.8z"/>
        </g>
        <g>
            <path class="st2" d="M28.3,3.7c13.6,0,24.6,11,24.6,24.6S41.9,53,28.3,53S3.7,41.9,3.7,28.3S14.8,3.7,28.3,3.7L28.3,3.7z"/>
            <path class="st3" d="M15.4,41.3c-8.9-8.9-9.6-22.8-2.1-32.4c-0.8,0.6-1.6,1.3-2.4,2.1c-9.6,9.6-9.6,25.2,0,34.8
                c9.6,9.6,25.2,9.6,34.8,0c0.8-0.8,1.5-1.6,2.1-2.4C38.2,50.8,24.3,50.1,15.4,41.3L15.4,41.3z"/>
        </g>
        <path d="M28.3,34.2c-4.1,0-8.3-1.6-11.4-4.7c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0c5.6,5.6,14.7,5.6,20.3,0
            c0.4-0.4,0.9-0.4,1.3,0c0.4,0.4,0.4,0.9,0,1.3C36.6,32.6,32.5,34.2,28.3,34.2L28.3,34.2z"/>
        <path class="st3" d="M28.3,38.1c-1.5,0-2.9-0.2-4.4-0.6c-0.5-0.1-0.8-0.6-0.6-1.1c0.1-0.5,0.6-0.8,1.1-0.6c2.5,0.7,5.2,0.7,7.8,0
            c0.5-0.1,1,0.1,1.1,0.6c0.1,0.5-0.1,1-0.6,1.1C31.3,37.9,29.8,38.1,28.3,38.1L28.3,38.1z"/>
        <path d="M22.1,23c-0.5,0-0.9-0.4-0.9-0.9c0-2.4-2-4.4-4.4-4.4c-2.4,0-4.4,2-4.4,4.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9
            c0-3.5,2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3C23,22.6,22.6,23,22.1,23L22.1,23z"/>
        <path d="M45.3,23c-0.5,0-0.9-0.4-0.9-0.9c0-2.4-2-4.4-4.4-4.4c-2.4,0-4.4,2-4.4,4.4c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
            c0-3.5,2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3C46.2,22.6,45.8,23,45.3,23L45.3,23z"/>
        <path class="st4" d="M12.9,24.7c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4C10.4,25.8,11.5,24.7,12.9,24.7
            L12.9,24.7z"/>
        <path class="st4" d="M43.8,24.7c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4C41.4,25.8,42.5,24.7,43.8,24.7
            L43.8,24.7z"/>
        </svg>
 </span>
</template>
<script>
export default {
    props:{
        size :{
            type : String,
            default : "svg-icon" 
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style scoped>
    svg{
        max-width: 100px;
    }

    .sat_icon{
        max-height: 100px;
        max-width: 100%;
    }
    #Calque_2 {
        max-width: 100px !important;
    }
    .st0{fill:#F2F2F2;}
    .st1{fill:#848484;}
    .st2{fill:#71c639c4;}
    .st3{fill:rgba(84, 163, 31, 0.411);}
    .st4{fill:#F1562E;}
</style>
