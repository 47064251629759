import {createStore} from "vuex"
// import VuexPersistence from "vuex-persist"
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls"
var ls = new  SecureLS({isCompression : false})

const LOGOUT ="LOGOUT"
const LOGIN =  "LOGIN"
const LOGIN_SUCCESS = "LOGIN_SUCCESS";

const store = createStore({
    state:{
        uToken : '',
        uIsLoggedIn : false,
        uData : null,
        uEmpleado : {
            emp_codigo : 0,
            emp_codfac : 0,
            emp_correo :""
        },
        uConfig : {
            cfCurrentModule : null,
            cfCurrentMenu :  null,
            //Lists
            cfMenuModel : null,
            cfModules : null,
            cfMenu : null,
            cfCiclo : null
        },
    },
    getters: {

        getUserId :state => {
            return state.uData.usr_codigo;
        },
        getEmpId :state => {
            return state.uData.usr_codemp;
        },

        getInitialName :state => {
            return state.uData.usr_nombre[0];
        },

        getCicloVigente :state => {
            return state.uConfig.cfCiclo;
        },

        isAuthenticated : state => {
            return state.uIsLoggedIn && state.uConfig.cfCurrentModule != null
        },

        isAuthorized : (state, getters) => (url)  =>{
            if(state.uConfig.cfMenu == null) return false;
            var menuAutorizacion =  state.uConfig.cfMenu.filter(x=> x.men_url ==  url.name);
            
            state.uConfig.cfCurrentMenu =  menuAutorizacion;
            
            return (getters.isAuthenticated && menuAutorizacion.length != 0)
        },

        evalAuth:(state) => (element, useToast = null) => {
            //useToast: show alert message.
            var restriccion = state.uConfig.cfCurrentMenu.restriccion;

            if(restriccion != undefined && restriccion.length > 0){
                var reject = restriccion.filter(x=> x.mer_elemento == element);
                if(reject.length > 0){ //rejected: Rechazado
                    if(useToast != null){
                        useToast.add({severity:'warn', summary:'Autorización', detail:'No está autorizado para acceder o realizar la acción.', group: 'br', life: 3000});
                    }
                    return false
                }
            }

            return true; //autorizado
        }
        
    },
    mutations: {
        [LOGIN](state){
            state.pending = true;
        },

        [LOGIN_SUCCESS] (state) {
            state.uIsLoggedIn = true;
            state.pending = false;
        },
        
        setUserData(state,value){
            state.uData = value;
        },
       
        setUserToken(state,value){
            state.uToken =  value;
        },
       
        setUserMenu(state,value){
            state.uConfig.cfMenu =  value;
        }, 
        //Set Menu
        setActiveMenu(state, value){
            state.uConfig.cfCurrentMenu = value
        },
        setMenuModel(state, value){
            state.uConfig.cfMenuModel = value
        },


        //Set Modulo
        setModule(state,value){
            state.uConfig.cfModules = value
        },

        setActiveModule(state, value){
            state.uConfig.cfCurrentModule = {...value}
        },

        setCicloActive(state,value){
            state.uConfig.cfCiclo = value
        },
        setEmpleado(state, value){
            state.uEmpleado.emp_codfac = value.emp_codfac
            state.uEmpleado.emp_codigo =  value.emp_codigo
            state.uEmpleado.emp_correo =  value.emp_correo
        },

        [LOGOUT](state){
            state.uIsLoggedIn =  false,
            state.uToken = '';
            state.uData = null
            state.uEmpleado = {
                emp_codigo : 0,
                emp_codfac : 0,
                emp_correo : ""
            }
            state.uConfig = {
                cfCurrentModule : null,
                cfCurrentMenu :  null,
                //Lists
                cfMenuModel : null,
                cfModules : null,
                cfMenu : null,
                cfCiclo :  null
            }
        }
    },
    actions:{
        

        //Set List of Modules
        userModule({commit},value){
            commit("setModule", value);
        },

        cicloActive({commit},value){
            commit("setCicloActive", value);
        },

        /*
            Set List of MenuModel
            data->module, data->menuList
        */
        createMenu({commit},data){ ///creating menu
            var menuModel = [];
           
            //Creating menu level One
            var menuList = data.menuList;
            var moduleObject =  data.module;
            var parentMenu =  menuList.filter(x=> x.men_padre == 0);
          
            parentMenu.forEach(md => {
                //Partial elemento from recursive Data
                var partialMenu = {
                    label: md.men_descripcion, 
                    icon: md.men_icon,
                    men_codigo : md.men_codigo,
                    
                };
                if(md.men_url)
                    if(md.men_url != "")
                        partialMenu.to = { name : md.men_url } 

                var elementMenu = addMenu(partialMenu, menuList); //Recursive function
                menuModel.push(elementMenu); //add to schema
            });
            commit("setActiveModule",moduleObject); 
            commit("setMenuModel", menuModel);
            commit("setUserMenu", menuList);

        },

        //Set Menu clicked
        currentMenu({commit, state}, url){
            var menu =  state.uConfig.cfMenu.filter(x=> x.men_url ==  url)[0];
            commit("setActiveMenu",menu); 
        },
        
        /*
            do login
            param : creds (Object) =>  { token : Object , modulos : Array , usuarios :  Object }
        */
        login({commit}, creds) {
            commit(LOGIN); // show spinner
            return new Promise(resolve => {
              setTimeout(() => {
                    commit("setUserToken",creds.token); 
                    commit("setUserData",creds.usuario);
                    commit(LOGIN_SUCCESS);
                    resolve();
                }, 1500);
            });
        },
        
        // do logout
        logout({commit}){
            commit(LOGOUT);
        }
    },
    plugins:[
        // new VuexPersistence({
        //     storage:window.localStorage
        // }).plugin
        createPersistedState({
            storage: {
              getItem: key => ls.get(key),
              setItem: (key, value) => ls.set(key, value),
              removeItem: key => ls.remove(key)
            }
          })
    ]
});


// eslint-disable-next-line no-unused-vars
function addMenu(menuCurrent,menuList){
    //Buscar los menus en este modulo "menuObject"
    var menuChildren =  menuList.filter(x=> 
        x.men_padre == menuCurrent.men_codigo) 
    
    if(menuChildren.length > 0){
        menuCurrent["items"] =  [];
        menuChildren.forEach((menu)=>{
            var item = {
                label: menu.men_descripcion, 
                icon: menu.men_icon,
                men_codigo : menu.men_codigo
            }
            if(menu.men_url != "")
                item.to = { name : menu.men_url }
            
            menuCurrent.items.push(item);
        });
    }
    return menuCurrent;
}

export default store