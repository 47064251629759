/*From VUE*/
import { createApp , reactive} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'
import store from './store'


// import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/layout/layout.scss';
import './assets/layout/flags/flags.css';

import PrimeVue from 'primevue/config'

import CodeHighlight from './AppCodeHighlight';

import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import BadgeDirective from 'primevue/badgedirective'
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button'
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FullCalendar from 'primevue/fullcalendar';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext'
import InputSwitch  from 'primevue/inputswitch'
import Menu from 'primevue/menu';
import MegaMenu from 'primevue/megamenu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import OrderList from 'primevue/orderlist';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple'
import ScrollPanel from 'primevue/scrollpanel';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';

import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import StyleClass from 'primevue/styleclass';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Textarea from 'primevue/textarea';
import TieredMenu from 'primevue/tieredmenu';
import Timeline from 'primevue/timeline';

import Tree from 'primevue/tree';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar'
import Tooltip from 'primevue/tooltip'



const app = createApp(App)

 //Configurando ruta de de global de axios
axios.defaults.baseURL = process.env.VUE_APP_API 
var token = store.state.uToken;
if(token)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;


   

app.config.globalProperties.$appState = reactive({
  inputStyle: 'outlined',
  logMessageList : []   //own variable
});
const appProperties =  app.config.globalProperties.$appState;

//Control de errores Axios 
axios.interceptors.response.use(function (response) {
  return response
  }, function (error) {
    appProperties.logMessageList = {... []}
    appProperties.logMessageList = [{content : error}]
    return Promise.reject(error)
});

//Manejador de errores global
app.config.globalProperties.$catchError = (ex) => {
    appProperties.logMessageList = {... []}
    appProperties.logMessageList = [{content : ex}]
}

app.config.globalProperties.$loadingSplash =  true;

/* Methods Generales*/
app.config.globalProperties.$methods = {
    groupBy(array, key){
        const result = {}
        if(Array.isArray(array))
        array.forEach(item => {
          if (!result[item[key]]){
            result[item[key]] = []
          }
          result[item[key]].push(item)
        })
        return result
      }
};


app.use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(PrimeVue,{ripple: true})
    .use(ConfirmationService)
    .use(ToastService)
    .use(VueCookies); 

/*Begin: Directives*/
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.directive('StyleClass',StyleClass)
/*End: Directives */
app.component("Avatar",Avatar);
app.component("AvatarGroup",AvatarGroup);
app.component("Breadcrumb",Breadcrumb)
app.component("Button",Button);
app.component("Calendar",Calendar)
app.component("Card",Card)
app.component("Checkbox",Checkbox)
app.component("Chip",Chip)
app.component("Column",Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ContextMenu', ContextMenu);
app.component("DataTable",DataTable);
app.component("Dialog",Dialog)
app.component("Divider",Divider);
app.component("Dropdown",Dropdown)
app.component("FullCalendar", FullCalendar)
app.component("InputNumber",InputNumber);
app.component("InputSwitch", InputSwitch)
app.component("InputText",InputText);
app.component('Menu', Menu);
app.component('Message',Message)
app.component("MegaMenu",MegaMenu);
app.component("Menubar",Menubar);
app.component("OrderList",OrderList)
app.component("OverlayPanel",OverlayPanel);
app.component("Paginator",Paginator)
app.component("Panel",Panel)
app.component("PanelMenu",PanelMenu)
app.component("Password",Password)
app.component("ProgressBar", ProgressBar)
app.component("ProgressSpinner", ProgressSpinner)
app.component("RadioButton",RadioButton)
app.component("ScrollPanel",ScrollPanel)
app.component("SelectButton",SelectButton)
app.component("Sidebar",Sidebar)
app.component("Skeleton",Skeleton )
app.component("SplitButton",SplitButton)


app.component("TabPanel", TabPanel)
app.component("TabView",TabView)
app.component("Textarea",Textarea)
app.component("TieredMenu", TieredMenu)
app.component("Timeline",Timeline)
app.component("Tree",Tree);
app.component('Toast', Toast);
app.component("Toolbar",Toolbar);
app.mount('#app')
