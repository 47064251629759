<template>
	<div class="layout-footer sm:flex-row flex-column">
        <div class="footer-logo-container">
            <!-- <img src="assets/layout/images/logo.svg" alt="" width="80"/> -->
            <span class="footer-text ml-1" style="margin-right: 5px">{{appName}} <small class="text-xs">{{appRelease}}</small></span>
        </div>
		<span class="copyright" style="margin-left: 5px">{{appCopy}}</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",

        data(){
            return{
                appName : process.env.VUE_APP_NAME,
                appCopy : process.env.VUE_APP_COPY,
                appRelease : process.env.VUE_APP_RELEASE
            }
        },
        created(){
            let currentDate = new Date()
            let currentYear = currentDate.getFullYear()
            this.appCopy = this.appCopy.replace("@year", currentYear)
        }
	}
</script>

<style scoped>

</style>