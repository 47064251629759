<template>

<!--Check Request -->
<span class="sat_icon">
    <svg  version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
	viewBox="0 0 56.7 56.7" style="enable-background:new 0 0 56.7 56.7;" xml:space="preserve">
        <g>
            <path class="st0" d="M707.4,263.9l6.7,13.5l14.9,2.2l-10.8,10.5l2.5,14.8l-13.3-7l-13.3,7l2.5-14.8l-10.8-10.5l14.9-2.2
                L707.4,263.9z M707.4,263.9"/>
            <path class="st1" d="M721.2,305.6l-13.8-7.2l-13.8,7.2l2.6-15.4l-11.2-10.9l15.4-2.2l6.9-14l6.9,14l15.4,2.2l-11.2,10.9
                L721.2,305.6z M686.6,279.8L697,290l-2.5,14.3l12.8-6.8l12.8,6.8l-2.5-14.3l10.4-10.1l-14.4-2.1l-6.4-13l-6.4,13L686.6,279.8z"/>
        </g>
        <g>
            <path class="st2" d="M28.3,3.7c13.6,0,24.6,11,24.6,24.6S41.9,53,28.3,53S3.7,41.9,3.7,28.3S14.8,3.7,28.3,3.7L28.3,3.7z"/>
            <path class="st3" d="M15.4,41.3c-8.9-8.9-9.6-22.8-2.1-32.4c-0.8,0.6-1.6,1.3-2.4,2.1c-9.6,9.6-9.6,25.2,0,34.8
                c9.6,9.6,25.2,9.6,34.8,0c0.8-0.8,1.5-1.6,2.1-2.4C38.2,50.8,24.3,50.1,15.4,41.3L15.4,41.3z"/>
        </g>
        <polyline class="st4" points="16.5,29.7 28.6,41.8 40.2,14.9 "/>
        </svg>
</span>
</template>
<script>

export default {
    props:{
        size :{
            type : String,
            default : "svg-icon" 
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style scoped lang="scss"  >
   
     .sat_icon{
        max-height: 100px;
        max-width: 100%;
    }
    #Calque_2 {
        max-width: 100px !important;
    }

    .st0{fill:#F2F2F2;}
    .st1{fill:#848484;}
    .st2{fill:#71c639c2;}
    .st3{fill:#54a31f6b;}
    .st4{fill:none;stroke:#231F20;stroke-width:5.6693;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>