<template>

<!--Bad Request -->
<span class="sat_icon">
    <svg  version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  width="100%" height="100%"
            viewBox="0 0 56.7 56.7" style="enable-background:new 0 0 56.7 56.7;" xml:space="preserve" >
        <g>
            <path class="st0"  d="M707.4,263.9l6.7,13.5l14.9,2.2l-10.8,10.5l2.5,14.8l-13.3-7l-13.3,7l2.5-14.8l-10.8-10.5l14.9-2.2
                L707.4,263.9z M707.4,263.9"/>
            <path class="st1" f d="M721.2,305.6l-13.8-7.2l-13.8,7.2l2.6-15.4l-11.2-10.9l15.4-2.2l6.9-14l6.9,14l15.4,2.2l-11.2,10.9
                L721.2,305.6z M686.6,279.8L697,290l-2.5,14.3l12.8-6.8l12.8,6.8l-2.5-14.3l10.4-10.1l-14.4-2.1l-6.4-13l-6.4,13L686.6,279.8z"/>
        </g>
        <g>
            <g>
                <path class="st2"  d="M28.3,3.7c13.6,0,24.6,11,24.6,24.6S41.9,53,28.3,53S3.7,41.9,3.7,28.3S14.8,3.7,28.3,3.7L28.3,3.7z"/>
                <path class="st3" d="M15.4,41.3c-8.9-8.9-9.6-22.8-2.1-32.4c-0.8,0.6-1.6,1.3-2.4,2.1c-9.6,9.6-9.6,25.2,0,34.8
                    c9.6,9.6,25.2,9.6,34.8,0c0.8-0.8,1.5-1.6,2.1-2.4C38.2,50.8,24.3,50.1,15.4,41.3L15.4,41.3z"/>
            </g>
            <path class="st3"  d="M24.8,35.4c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.4-0.3-1,0.1-1.3c1.2-0.9,2.6-1.5,4.2-1.5c1.4,0,2.9,0.5,4,1.3
                c0.4,0.3,0.5,0.9,0.2,1.3c-0.3,0.4-0.9,0.5-1.3,0.2c-0.8-0.6-1.8-1-2.9-1c-1.1,0-2.2,0.4-3,1.1C25.2,35.3,25,35.4,24.8,35.4
                L24.8,35.4z"/>
            <path d="M20.8,25.7c0-0.4-0.1-0.8-0.2-1.1c0.4,0,0.7,0.1,1.1,0.1c1.1,0,2.2-0.1,3.5-0.5c0.5-0.1,0.8-0.6,0.6-1.1
                c-0.1-0.5-0.6-0.8-1.1-0.6c-6.5,1.8-9.3-2.4-9.4-2.5c-0.3-0.4-0.8-0.5-1.3-0.3c-0.4,0.3-0.5,0.8-0.3,1.3c0,0,0.6,1,1.9,1.9
                c-1,0.6-1.7,1.7-1.7,2.9c0,1.9,1.5,3.4,3.4,3.4C19.3,29.1,20.8,27.6,20.8,25.7L20.8,25.7z"/>
            <path d="M28.8,28.5c-4.5,0-8.5,2.8-10,7c-0.2,0.5,0.1,1,0.6,1.2c0.5,0.2,1-0.1,1.2-0.6c1.2-3.5,4.5-5.8,8.3-5.8
                c3.7,0,7.1,2.3,8.3,5.8c0.1,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.3-0.1c0.5-0.2,0.7-0.7,0.6-1.2C37.3,31.3,33.3,28.5,28.8,28.5
                L28.8,28.5z"/>
            <path d="M42.5,19.6c-0.4-0.3-1-0.2-1.3,0.3c-0.1,0.2-2.9,4.3-9.4,2.5c-0.5-0.1-1,0.2-1.1,0.6c-0.1,0.5,0.2,1,0.6,1.1
                c1.3,0.4,2.5,0.5,3.5,0.5c0.4,0,0.8,0,1.1-0.1c-0.1,0.3-0.2,0.7-0.2,1.1c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4
                c0-1.3-0.7-2.4-1.7-2.9c1.3-0.9,1.9-1.9,1.9-1.9C43.1,20.5,42.9,19.9,42.5,19.6L42.5,19.6z"/>
            <path class="st4" d="M18.4,24.4c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9C17.5,24.8,17.9,24.4,18.4,24.4
                L18.4,24.4z"/>
            <path class="st4" d="M40.1,24.4c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
                C39.2,24.8,39.6,24.4,40.1,24.4L40.1,24.4z"/>
        </g>
    </svg>
</span>
</template>
<script>

export default {
    props:{
        size :{
            type : String,
            default : "svg-icon" 
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style scoped lang="scss" >

    .sat_icon{
        max-height: 100px;
        max-width: 100%;
    }
    
    #Calque_2 {
        max-width: 100px !important;
    }

    .st0{fill:#F2F2F2;}
    .st1{fill:#848484;}
    .st2{fill:#f1552ed8;}
    .st3{fill:#e446275e;}
    .st4{fill:#FFFFFF;}
</style>