<template>
    <transition name="fade" appear>
        <div class="flex justify-content-center flex-wrap my-1 " v-if="visible">
            <div class="w-full xl:w-10  flex  flex-column border-round bg-light-danger p-2  border-dashed-custom  border-danger my-1 shadow-2" >  
                <div class="flex flex-shrink-0 align-content-center">
                    <ErrorCircleIcon :size="'svg-icon-2tx'" class="mr-3"/>
                    <div class="flex justify-content-between align-items-start flex-grow-1 flex-nowrap">
                        <div class=" font-semibold">
                            <h5 class="text-danger font-bold py-0 my-0" style="letter-spacing: 1px;" >
                              {{status}} {{statusText}}
                            </h5>
                            <div class="text-base text-gray-600 pr-2 "> {{details}} </div>
                            <div class="">
                                <div v-for="item in itemDetails" :key="item" class="flex align-items-center"><AngleDoubleIcon/>{{getValueProperty(item)}}</div>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="text-danger "><i class="pi pi-times pi-pw mr-1" @click="close($event)" v-ripple/> </a>
                    </div>
                </div> 
            </div>
        </div>
    </transition>
</template>

<script>
import { ErrorCircle,AngleDouble }  from '@/components/svg/_svgHelper.js'

import Ripple from 'primevue/ripple';

export default {
    emits: ["close"],
    props: {
        sticky: {
            type: Boolean,
            default: true
        },
        content: null,
        life: {
            type: Number,
            default: 3000
        }
    },
    timeout: null,
    data() {
        return {
            visible: true,
            status : "",
            statusText : "",
            details : "",
            itemDetails : [],
            unAuth : false
        }
    },
    methods: {
        close(event) {
            this.visible = false;
            this.$emit('close', event);
        },

        error(){
            this.status =  "";
            this.statusText = "";
            this.details = "";
            this.itemDetails = [];
            var error =   this.content


            if (error.response){
                var resp =  error.response; 
                this.status =  resp.status;
                this.statusText = resp.statusText;
                if(typeof resp.data === 'object' && resp.data !== null){
                    this.details =  resp.data.title;
                    if(resp.data.errors){
                        this.itemDetails.push(resp.data.errors);   
                    }
                }else if(Array.isArray(resp.data)) {
                    this.itemDetails = resp.data
                }else if(resp.data.trim()){
                    this.details =  resp.data
                    var splitDetails =  this.details.split("\r\n");
                    if(Array.isArray(splitDetails))
                        this.details =  splitDetails[0]
                }else{
                    if(resp.status === 401){
                        this.details = "Parece que su sesión ha finalizado, favor cerrar sesión e iniciar nuevamente";
                    }
                }
            }
            else if (error.request) {
                this.statusText = "¡Request Error!";
                this.details = `Error : No se obtuvo una respuesta válida a la petición al servidor`
            }
            else if(error.message){
                this.statusText = error.message;
            }else{
                this.statusText = error
            }
        },

        getValueProperty(obj){
            var element = "";
            Object.getOwnPropertyNames(obj).forEach((val)=> {
                element =  `${val}: ${obj[val]}` ; 
            });

            return element;
        }
    },

    mounted() {
        if (!this.sticky) {
            setTimeout(() => {
                this.visible = false;
                if(this.unAuth){
                    this.$store.dispatch('logout');
                    this.$router.push({name : "home"})
                }
            }, this.life);
        }

        this.error()
    },


    components: {
        ErrorCircleIcon : ErrorCircle,
        AngleDoubleIcon : AngleDouble
    },
    directives: {
        'ripple': Ripple
    }
}
</script>


<style>

.fade-enter-from {
    opacity: 0;
}

.fade-enter-active {
    transition: opacity .3s;
}

.fade-leave-from {
    max-height: 1000px;
}

.fade-leave-to {
    max-height: 0;
    opacity: 0;
    margin: 0 !important;
}

.fade-leave-active {
    overflow: hidden;
    transition: max-height .3s cubic-bezier(0, 1, 0, 1), opacity .3s, margin .15s;
}

</style>
