<!-- Menubar from PrimeVue  With _Custom Layout AppProfile-->
<template>
    <div class="menu-toolbar">
        <Menubar :model="model" class="border-noround">
            <template #start>
                <router-link to="/">
                    <img alt="logo" src="@/assets/layout/images/logo-horizontal-white.png" height="40" class="p-mr-2">
                </router-link>
            </template>
            <template #end>
                <AppProfile />
            </template>
        </Menubar>
    </div>
</template>

<script>

import AppProfile from "@/layout/AppProfile"
export default {
	props: {
		model: Array
	},
    methods: {
       
    },
    components : {
        "AppProfile" : AppProfile
    }
}
</script>