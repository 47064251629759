<!-- Menu from PrimeVue with Custom sass _menu.scss -->
<template>
	<div class="flex align-items-center pr-2">
		<span class="text-white" v-if="appMode != 'production'"> {{appMode}}</span>

		<Button type="button" label="Filtro"   class="p-button-text mr-2 p-1" @click="toggleOver"  v-if="!hiddenButtons">
			<LayoutGridIcon class="flex align-items-center" :size="'svg-icon-1'"/> 
		</Button>

        <a href="javascript:void(0)" @click="toggleMenu" class="text-primary" v-if="!hiddenButtons">
			<span class="text-white mx-1">¡Hola!, <strong>{{$store.state.uData.usr_usuario}}</strong></span><Avatar :label="$store.getters.getInitialName" />
		</a>
	</div>
	<Menu ref="menu" :model="profileMenuItems" :popup="true" />

	<OverlayPanel ref="appList" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '320px'}" class="p-0" id="overModule">
		<div class="flex flex-column align-items-center bg-no-repeat border-round-top px-2 py-3 this-bgcustom mb-1">
			<h4 class="text-white font-bold mb-1">Enlaces</h4>
			<span class="label label-inline label-light-success " >Modulos disponibles</span>
		</div>
		<div class="flex  flex-column" v-if="$store.state.uIsLoggedIn">
			<div  class="flex flex-shrink-0  " v-for="mod in $store.state.uConfig.cfModules" :key="mod">
				<a href="#" class="btn border-round  w-full hover:bg-primary text-gray-700 flex justify-content-center align-items-center" @click="selectModulo_onClick(mod)"> 
					<LayoutGridIcon class="mr-1 flex align-items-center"/> 
					<span class=" font-bold ">{{mod.mod_nombre}}</span>
				</a>
			</div>
		</div>

	</OverlayPanel>
</template>

<script>
	import LayoutGridIcon from "@/components/svg/LayoutGridsvg"
	import  AuthService from '@/service/authService'
	export default {
		props:{
			hiddenButtons:{
				type: Boolean,
				default: false
			}
		},
		components: { LayoutGridIcon  },
		authService : null,
		data() {
			return {
				expanded: false,
                profileMenuItems: [
					
					{
						separator: true
					},
					
					{
						label: 'Perfil',
						icon: 'pi pi-user',
                        command: () => {
							this.$router.push({ name: 'profile'})
                        }
					},
					{
						label: 'Cerrar Sesión',
						icon: 'pi pi-sign-out',
                        command: () => {
                            this.$store.dispatch('logout');
							this.$router.push("/")
                        }
					}
					
				],
				appMode : ""
			}
		},
		created(){
			this.appMode = process.env.NODE_ENV
			this.authService =  new AuthService(this.axios)
		},
		methods: {
			toggleOver(event) { // OverlayPanel
				this.$refs.appList.toggle(event);
			},
			toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},
			async selectModulo_onClick(module){
				this.$refs.appList.toggle();
				await this.authService.logMenu({
                    cod_usuario: this.$store.state.uData.usr_codigo,
                    cod_modulo: module.mod_codigo
				}).then(res=> {
						if(res == ""){
							this.$toast.add({severity:'error', summary:'Menú no encontrado', detail:"Respuesta no contiene datos que cargar ", life: 3000});
							return;
						} 
					this.$store.dispatch("createMenu", { module: module, menuList : res });
					this.$router.go(this.$router.currentRoute)
				}).catch(ex => {
						if(ex.response.status == 404)
							this.$toast.add({severity:'error', summary:'Menú no encontrado', detail:ex.response.data, life: 3000});
				});
			}
		}
	}
</script>


<style scope>
	.this-bgcustom {
		background-image: url("../assets/layout/images/eva_bg.jpg");
	}
	#overModule .p-overlaypanel-content{
		padding: 0 !important;
	}
</style>