// JSDoc (http://usejsdoc.org/)
/** @module tableroService */


export default class tableroService{
    /**
     * @param axios Axios Instance
     */
    constructor(axios){
        this.axios =  axios;
    }


    /**
     * Obtener lista de tablero
     * @param {Integer} codUser Código de usuario
     * @param {Integer} codMod Código de modulo
     * @return {json} JsonObjectList with tab_sql_command string json
     */
    async getTableroByUser(codUser,codMod){
        return this.axios.get(`/Tablero/GetTableroByUser/${codUser}/${codMod}`).then(res => res.data);
        
    }
}