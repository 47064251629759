// JSDoc (http://usejsdoc.org/)
/** @module AuthService */


export default class AuthService{
    /**
     * @param axios Axios Instance
     */
    constructor(axios){
        this.axios =  axios;
    }


    async login(data) {
        return this.axios.post('Auth/Login', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);

    }

    async logMenu(data) {
        return this.axios.post('/Auth/LogMenu', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);

    }

}
