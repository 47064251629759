<template>

<!--Neutral Request -->
 <span class="">
    <svg version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
    viewBox="0 0 56.7 56.7" style="enable-background:new 0 0 56.7 56.7;" xml:space="preserve">
    <g>
        <path class="st0" d="M707.4,263.9l6.7,13.5l14.9,2.2l-10.8,10.5l2.5,14.8l-13.3-7l-13.3,7l2.5-14.8l-10.8-10.5l14.9-2.2
            L707.4,263.9z M707.4,263.9"/>
        <path class="st1" d="M721.2,305.6l-13.8-7.2l-13.8,7.2l2.6-15.4l-11.2-10.9l15.4-2.2l6.9-14l6.9,14l15.4,2.2l-11.2,10.9
            L721.2,305.6z M686.6,279.8L697,290l-2.5,14.3l12.8-6.8l12.8,6.8l-2.5-14.3l10.4-10.1l-14.4-2.1l-6.4-13l-6.4,13L686.6,279.8z"/>
    </g>
    <g>
        <path class="st2" d="M28.3,3.7c13.6,0,24.6,11,24.6,24.6S41.9,53,28.3,53S3.7,41.9,3.7,28.3S14.8,3.7,28.3,3.7L28.3,3.7z"/>
        <path class="st3" d="M15.4,41.3c-8.9-8.9-9.6-22.8-2.1-32.4c-0.8,0.6-1.6,1.3-2.4,2.1c-9.6,9.6-9.6,25.2,0,34.8
            c9.6,9.6,25.2,9.6,34.8,0c0.8-0.8,1.5-1.6,2.1-2.4C38.2,50.8,24.3,50.1,15.4,41.3L15.4,41.3z"/>
    </g>

    <!-- Boca -->

        
    <path  d="M28.3,38.1c-1.5,0-2.9-0.2-4.4-0.6c-0.5-0.1-0.8-0.6-0.6-1.1c0.1-0.5,0.6-0.8,1.1-0.6c2.5,0.7,5.2,0.7,7.8,0
        c0.5-0.1,1,0.1,1.1,0.6c0.1,0.5-0.1,1-0.6,1.1C31.3,37.9,29.8,38.1,28.3,38.1L28.3,38.1z"/>

    <!--Cejas-->
    <path d="M 11 22.1 c -1 0 -2 -1 0 -1.5 c 2 0 11 0 13 0 c 2 0.5 1 1.5 0 1.5 z"/>
    <path d="M 33 22.1 c -1 0 -2 -1 0 -1.5 c 2 0 11 0 13 0 c 2 0.5 1 1.5 0 1.5 z"/>

    <path d="M20.8,25.8c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4C19.3,29.1,20.8,27.6,20.8,25.8L20.8,25.8z
            "/>
    <path d="M39.2,22.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4C42.6,23.9,41.1,22.4,39.2,22.4L39.2,22.4z
        "/>

    <path class="st4" d="M17.8,24.4c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
        C16.9,24.8,17.3,24.4,17.8,24.4L17.8,24.4z"/>
    <path class="st4" d="M39.6,24.4c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9C38.7,24.8,39.1,24.4,39.6,24.4
        L39.6,24.4z"/>

    </svg>
 </span>
</template>
<script>
export default {
    props:{
        size :{
            type : String,
            default : "svg-icon" 
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style scoped>
    svg{
        max-width: 100px;
    }
    .st0{fill:#F2F2F2;}
    .st1{fill:#848484;}
    .st2{fill:#fdba3fd7;}
    .st3{fill:#e3940267;}
    .st4{fill:#ffffffbb;}
</style>
