<template>
	<div class="grid">
        <!--Begin: Loader /Waiter -->
		<div class="col-12 md:col-10 xl:col-10 xl:col-offset-1  md:col-offset-1 h-full" >
			<div class="grid">
				<div class="col-12">
					<div class="card">	
						<h6 class="font-bold my-0"> <span style="color:gray;filter: grayscale(100%);"> 👋</span> Hola ¡Bienvenido a <span class="text-primary">Seguimiento a la labor</span>! Antes de empezar te mostramos un resumen o ve directamenta  <router-link :to="{ name: 'PlaneacionIndex'}"> Lista de Plan Académico. </router-link> </h6>
					</div>
				</div>
				<div class="col-12">
					<div class="grid" v-if="!loaded">
						<div class="col-12 md:col-6 xl:col-4">
							<Skeleton  width="100%" height="125px"></Skeleton>
						</div>
						<div class="col-12 md:col-6 xl:col-4">
							<Skeleton  width="100%" height="125px"></Skeleton>
						</div>
						<div class="col-12 md:col-6 xl:col-4">
							<Skeleton  width="100%" height="125px"></Skeleton>
						</div>
						<div class="col-12 md:col-6 xl:col-4">
							<Skeleton  width="100%" height="125px"></Skeleton>
						</div>
					</div>
					
					<div class="grid" v-else>
						<div class="col-12 md:col-6 xl:col-3 " v-for="card in tableroList.filter(x=> x.tab_tipo == 'CARD')" :key="card">
							<div class="p-card widget-box border-bottom-2" :class="'wb-' + card.tab_color.toLowerCase()" >
								<span class="inline wb-icon  border-round" style="width:2.5rem;height:2.5rem;">
									<i class="text-xl" :class="card.tab_icon"></i>
								</span>
								<span class="mx-2  font-semibold text-lg mb-2 " > {{card.tab_titulo}}</span>
								<div class="grid widget-box-detail mt-2"> 
									<div class="col-6" v-for="(detail,key) in JSON.parse(card.tab_sql_command)[0]" :key="detail">
										<div class="font-semibold text-2xl text-center text-gray-600"    >{{detail}} </div>
										<div class="font-semibold text-center text-gray-500">{{key}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				
				<div class="col-12 md:col-4" v-for="time in tableroList.filter(x=> x.tab_tipo == 'TIME')" :key="time">
					<div class="p-card"  >
						<div class="flex flex-column mb-3">
							<h6 class="font-bold my-0 text-lg ">{{time.tab_titulo}} </h6>
							<span class="text-muted">{{time.tab_descripcion}}</span>
						</div>

						<p  v-if="JSON.parse(time.tab_sql_command).length == 0" class="font-italic text-center">No hay datos que mostrar.</p>

						<Timeline :value="JSON.parse(time.tab_sql_command)" class="customized-timeline">
							<template #marker="slotProps">
								<span class="custom-marker shadow-2"  :class="'bg-light-' + slotProps.item.color +' text-' + slotProps.item.color">
									<i :class="slotProps.item.status == 'S' ? 'pi pi-check' : (slotProps.item.status == 'N' ? 'pi pi-times' :  '') "></i>
								</span>
							</template>
							<template #content="slotProps" class="my-2">
								<div class="flex align-items-center justify-content-between">
									<p class="text-primary font-semibold my-0">R{{slotProps.item.keyCode}}, {{DateFormat(slotProps.item.createAt)}} </p>
									<h6 class="my-0 font-bold text-lg" :class="'text-' + slotProps.item.color"> {{slotProps.item.code}}</h6>
								</div>
								<span class="text-xs my-3 text-muted">{{slotProps.item.description}} </span>
								<div class="my-4"></div>
							</template>
						</Timeline>
					</div>
					
				</div>
			</div>
			<div class="border-noround " >
				<div class="flex  flex-column align-items-center text-center">
					<img src="@/assets/layout/images/logo-vertical.png" class="w-12 md:w-6 mb-2"/>
				</div>
            </div>
        </div>

		
	</div>


</template>
<script>

import TableroService from  "@/service/tableroService.js"
import moment from 'moment'

export default {
	tableroService : null,
	data(){
		return{
			tableroList:[],
			loaded : false
		}
	},
	created(){
		this.tableroService =  new TableroService(this.axios);
	},
	async mounted(){
		try{
			this.tableroList =  await  this.tableroService.getTableroByUser(this.$store.getters.getUserId, this.$store.state.uConfig.cfCurrentModule.mod_codigo)
		}catch(ex){
			var msg = ex;
                if(msg.response)
                    msg =  msg.response.data
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
		}finally{
			this.loaded =  true
		}
	},
	methods:{
		DateFormat(data) {
            return moment(data).format('DD/MM/YYYY')
        },
	},
	components:{
		
	}
	
}
</script>

<style lang="scss" scoped>
	
	.widget-box {
		border-left: 5px solid;
		&.wb-warning{
			border-left-color:#FFE2E5 !important;
			&:nth-child(1){
				color:red
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #FEDDC7;
			}
		}
		&.wb-primary{
			border-left-color:#E1F0FF !important;
			&:nth-child(1){
				color:var(--primary-color);
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #c7dffe;
				color:  var(--primary-color);
			}
		}
		&.wb-danger{
			border-left-color:#ffe1e1 !important;
			&:nth-child(1){
				color: #F64E60 !important;
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #fec7c7;
				color: #F64E60;
			}
		}
		&.wb-info{
			border-left-color:#7986CB !important;
			&:nth-child(1){
				color: #7986CB;
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #7986CB;
				color: #303F9F;
			}
		}
		&.wb-success{
			border-left-color:#C9F7F5 !important;
		}
		
	}
	.widget-box-detail .col-6:first-child {
		border-right: 1px solid #dee2e6;
	}


//
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
::v-deep(.p-timeline-event-content)
::v-deep(.p-timeline-event-opposite) {
    line-height: 1;
}	


::v-deep(.customized-timeline) {
	.p-timeline-event:nth-child(even) {
		flex-direction: row !important;
		
		.p-timeline-event-content {
			text-align: left !important;
		}
	}

	.p-timeline-event-opposite{
			flex: none !important;

	}
	.p-card {
		margin-top: 1rem;
	}
}

</style>