<template>

<!--Happy Request -->

    <svg class="sat_icon" version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
	viewBox="0 0 56.7 56.7" style="enable-background:new 0 0 56.7 56.7;" xml:space="preserve">
        <g>
            <path class="st0" d="M707.4,263.9l6.7,13.5l14.9,2.2l-10.8,10.5l2.5,14.8l-13.3-7l-13.3,7l2.5-14.8l-10.8-10.5l14.9-2.2
                L707.4,263.9z M707.4,263.9"/>
            <path class="st1" d="M721.2,305.6l-13.8-7.2l-13.8,7.2l2.6-15.4l-11.2-10.9l15.4-2.2l6.9-14l6.9,14l15.4,2.2l-11.2,10.9
                L721.2,305.6z M686.6,279.8L697,290l-2.5,14.3l12.8-6.8l12.8,6.8l-2.5-14.3l10.4-10.1l-14.4-2.1l-6.4-13l-6.4,13L686.6,279.8z"/>
        </g>
        <g>
            <g>
                <path class="st2" d="M28.3,3.7c13.6,0,24.6,11,24.6,24.6S41.9,53,28.3,53S3.7,41.9,3.7,28.3S14.8,3.7,28.3,3.7L28.3,3.7z"/>
                <path class="st3" d="M15.4,41.3c-8.9-8.9-9.6-22.8-2.1-32.4c-0.8,0.6-1.6,1.3-2.4,2.1c-9.6,9.6-9.6,25.2,0,34.8
                    c9.6,9.6,25.2,9.6,34.8,0c0.8-0.8,1.5-1.6,2.1-2.4C38.2,50.8,24.3,50.1,15.4,41.3L15.4,41.3z"/>
            </g>
            <path d="M22.6,26.7c-0.3,0-0.6-0.2-0.8-0.4c-0.8-1.4-2.3-2.2-3.9-2.2c-1.6,0-3.1,0.8-3.9,2.2c-0.3,0.4-0.8,0.6-1.3,0.3
                c-0.4-0.3-0.6-0.8-0.3-1.3c1.2-1.9,3.2-3.1,5.4-3.1c2.3,0,4.3,1.2,5.4,3.1c0.3,0.4,0.1,1-0.3,1.3C23,26.7,22.8,26.7,22.6,26.7
                L22.6,26.7z"/>
            <path d="M43.4,26.7c-0.3,0-0.6-0.2-0.8-0.4c-0.8-1.4-2.3-2.2-3.9-2.2c-1.6,0-3.1,0.8-3.9,2.2c-0.3,0.4-0.8,0.6-1.3,0.3
                c-0.4-0.3-0.6-0.8-0.3-1.3c1.2-1.9,3.2-3.1,5.4-3.1c2.3,0,4.3,1.2,5.4,3.1c0.3,0.4,0.1,1-0.3,1.3C43.7,26.7,43.6,26.7,43.4,26.7
                L43.4,26.7z"/>
            <path d="M28.3,40.1c-4.9,0-9.9-1.9-13.7-5.7c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0c6.8,6.8,17.9,6.8,24.7,0
                c0.4-0.4,0.9-0.4,1.3,0c0.4,0.4,0.4,0.9,0,1.3C38.2,38.3,33.3,40.1,28.3,40.1L28.3,40.1z"/>
            <path class="st3" d="M28.3,43.7c-1.5,0-2.9-0.2-4.4-0.6c-0.5-0.1-0.8-0.6-0.6-1.1c0.1-0.5,0.6-0.8,1.1-0.6c2.5,0.7,5.2,0.7,7.8,0
                c0.5-0.1,1,0.1,1.1,0.6c0.1,0.5-0.1,1-0.6,1.1C31.3,43.5,29.8,43.7,28.3,43.7L28.3,43.7z"/>
        </g>
    </svg>
</template>
<script>
export default {
    props:{
        size :{
            type : String,
            default : "svg-icon" 
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style scoped>

    .sat_icon{
        max-height: 100px;
        max-width: 100%;
    }
    #Calque_2 {
        max-width: 100px !important;
    }
    svg{
        max-width: 100px;
    }
    .st0{fill:#F2F2F2;}
    .st1{fill:#848484;}
    .st2{fill:#43b7d19f;}
    .st3{fill:#208b9e9c;}
</style>
