<template>

<!--Upset Request -->
 <span class="">
    <svg version="1.1" id="Calque_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
	viewBox="0 0 56.7 56.7" style="enable-background:new 0 0 56.7 56.7;" xml:space="preserve">
    <g>
        <path class="st0"   d="M707.4,263.9l6.7,13.5l14.9,2.2l-10.8,10.5l2.5,14.8l-13.3-7l-13.3,7l2.5-14.8l-10.8-10.5l14.9-2.2
            L707.4,263.9z M707.4,263.9"/>
        <path class="st1" d="M721.2,305.6l-13.8-7.2l-13.8,7.2l2.6-15.4l-11.2-10.9l15.4-2.2l6.9-14l6.9,14l15.4,2.2l-11.2,10.9
            L721.2,305.6z M686.6,279.8L697,290l-2.5,14.3l12.8-6.8l12.8,6.8l-2.5-14.3l10.4-10.1l-14.4-2.1l-6.4-13l-6.4,13L686.6,279.8z"/>
    </g>
    <g>
        <g>
            <path class="st2" d="M28.3,3.7c13.6,0,24.6,11,24.6,24.6S41.9,53,28.3,53S3.7,41.9,3.7,28.3S14.8,3.7,28.3,3.7L28.3,3.7z"/>
            <path class="st3" d="M15.4,41.3c-8.9-8.9-9.6-22.8-2.1-32.4c-0.8,0.6-1.6,1.3-2.4,2.1c-9.6,9.6-9.6,25.2,0,34.8
                c9.6,9.6,25.2,9.6,34.8,0c0.8-0.8,1.5-1.6,2.1-2.4C38.2,50.8,24.3,50.1,15.4,41.3L15.4,41.3z"/>
        </g>
        <path class="st3" d="M31.4,39.1c-0.1,0-0.3,0-0.4-0.1c-0.8-0.4-1.7-0.7-2.7-0.7c-0.9,0-1.9,0.2-2.7,0.7c-0.4,0.2-1,0.1-1.2-0.4
            c-0.2-0.4-0.1-1,0.4-1.2c1.1-0.6,2.3-0.9,3.5-0.9c1.2,0,2.4,0.3,3.5,0.9c0.4,0.2,0.6,0.8,0.4,1.2C32.1,38.9,31.8,39.1,31.4,39.1
            L31.4,39.1z"/>
        <path d="M28.5,32.8c-3.8,0-7.3,1.5-10,4.1c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0c2.3-2.3,5.4-3.6,8.7-3.6
            c3.3,0,6.4,1.3,8.7,3.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.3C35.8,34.2,32.2,32.8,28.5,32.8
            L28.5,32.8z"/>
        <path d="M19,16.4c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,2.7-2.2,4.9-4.9,4.9c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9
            C16,23.1,19,20.1,19,16.4L19,16.4z"/>
        <path d="M44.4,21.3c-2.7,0-4.9-2.2-4.9-4.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,3.7,3,6.7,6.7,6.7
            c0.5,0,0.9-0.4,0.9-0.9C45.3,21.7,44.9,21.3,44.4,21.3L44.4,21.3z"/>
        <path d="M20.8,25.8c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4C19.3,29.1,20.8,27.6,20.8,25.8L20.8,25.8z
            "/>
        <path d="M39.2,22.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4C42.6,23.9,41.1,22.4,39.2,22.4L39.2,22.4z
            "/>
        <path class="st4" d="M17.8,24.4c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
            C16.9,24.8,17.3,24.4,17.8,24.4L17.8,24.4z"/>
        <path class="st4" d="M39.6,24.4c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9C38.7,24.8,39.1,24.4,39.6,24.4
            L39.6,24.4z"/>
    </g>
    </svg>
 </span>
</template>
<script>
export default {
    props:{
        size :{
            type : String,
            default : "svg-icon" 
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style scoped>
    svg{
        max-width: 100px;
    }
    .st0{fill:#F2F2F2;}
    .st1{fill:#848484;}
    .st2{fill:#e0781ecc;}
    .st3{fill:#af5e1c48;}
    .st4{fill:#FFFFFF;}
</style>
