<template>
    <CustomLoader  v-if="loader"/>
    <Toast />
    <Toast position="bottom-right" group="br" />
    <ConfirmDialog  :style="{width: '420px'}"></ConfirmDialog>

	<div :class="containerClass" class="layout-horizontal layout-theme-light"   v-if="$store.getters.isAuthenticated && !$route.meta.Layout">
        <AppMenu :model="$store.state.uConfig.cfMenuModel" /> 
        <div class="layout-main-container">
            <div class="layout-main">
               
                <!--Begin: Error Indicator -->
                    <CustomMessage :size="'svg-icon-2tx'"  v-for="msg of $appState.logMessageList" :key="msg" :content="msg.content" :sticky="false"> </CustomMessage>
                <!--End: Error Indicator -->
                <router-view />
            </div>
            <AppFooter /> 
        </div>
	</div>
    <!--Begin: Layout 2 -->
    <div  class="layout-plain" v-if="$route.meta.Layout == 'plainLayout'">
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transition || 'fade'">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
    <!--End: Layout 2 -->

    <!--Begin: Layout 3 -->
    <div  class="layout-check" v-if="$route.meta.Layout == 'customLayout'">
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transition || 'fade'">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
    <!--End: Layout 3 -->

</template>

<script>
import AppMenu from '@/layout/AppMenu.vue';
import AppFooter from '@/layout/AppFooter.vue';
// import Login from '@/layout/Login';
import CustomLoader from "@/components/CustomLoader"
import CustomMessage from './components/cards/CustomMessage.vue';
export default {
    data() {
        return {
            layoutMode: 'static',
            loader :  true,
            layoutColorMode: 'dark',
            staticMenuInactive: true,
            overlayMenuActive: false,
            mobileMenuActive: false,

            
        }
    },
    async mounted(){

        setTimeout(() => {
                this.loader = false
            }, 1000)
        this.$primevue.config.locale.accept = 'Si';
        this.$primevue.config.locale.reject = 'No';
        this.$primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
        this.$primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        this.$primevue.config.locale.dayNamesMin = ["Do","Lu","Mar","Mi","Ju","Vi","Sa"],
        this.$primevue.config.locale.monthNames = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
        this.$primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    },
        
    methods: {
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$appState.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
    },
    beforeUpdate() {
      
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
        // "Login" : Login,
        "CustomLoader" : CustomLoader,
        CustomMessage
    }
}
</script>

<style lang="scss">
    @import './App.scss';
</style>
