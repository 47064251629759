<template>
    <div class="splash-screen" style="z-index: 5000">
        <div class="splash-container">
            <div class="splash-double-bounce1"></div>
            <div class="splash-double-bounce2"></div>
        </div>
    </div>
</template>

<script>
export default {
    name :"CustomLoader"
}
</script>